import PlentyBody from '#app/components/typography/plenty-body'
import PlentyButtonsAndLinks from '#app/components/typography/plenty-buttons-and-links'
import PlentyBodyLink from '#app/components/ui/plenty-body-link'
import useAmplitudeContext from '#app/hooks/use-amplitude-context'
import { type CampaignStoryblok } from '#types/component-types-sb'

interface CampaignTextOnlyProps {
	blok: CampaignStoryblok
}

const CampaignTextOnly = ({ blok }: CampaignTextOnlyProps) => {
	const { trackAmplitudeEvent } = useAmplitudeContext()
	return (
		<div className="flex flex-col gap-2 px-4">
			<PlentyButtonsAndLinks as="nav-sm">{blok.headline}</PlentyButtonsAndLinks>
			<PlentyBody>{blok.bodyTextCampaign}</PlentyBody>
			<PlentyBodyLink
				link="stores"
				as="nav-sm"
				onClickCapture={() =>
					trackAmplitudeEvent('campaign text only clicked', {
						campaignHeadline: blok.headline,
						campaignBody: blok.bodyTextCampaign,
						campaignLink: blok.linkAndText,
					})
				}
			>
				{blok.linkAndText}
			</PlentyBodyLink>
		</div>
	)
}

export default CampaignTextOnly
