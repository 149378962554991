import { Link } from '@remix-run/react'
import { storyblokEditable } from '@storyblok/react'
import ContentBlok from '#app/components/storyblok/content-blok'
import useAmplitudeContext from '#app/hooks/use-amplitude-context'
import { type MarketingImageCardStoryblok } from '#types/component-types-sb'
import BlackBackgroundColorForImages from '../black-background-color-for-images'

const MarketingImageCard = ({ blok }: MarketingImageCardStoryblok) => {
	const { trackAmplitudeEvent } = useAmplitudeContext()
	const renderContentBlock = (block: any, index: number) => {
		return (
			<ContentBlok
				blok={block}
				key={`${block._uid}-${index}`}
				_uid={''}
				component={'contentBlok'}
			/>
		)
	}

	const contentBlok = Array.isArray(blok.contentBlok) ? blok.contentBlok : []
	return (
		<div
			{...storyblokEditable(blok)}
			key={blok._uid}
			className="group relative w-full overflow-hidden rounded-[8px] bg-cover bg-center bg-no-repeat md:rounded-[16px]"
			onClickCapture={() =>
				trackAmplitudeEvent('marketing image card clicked', {
					linkToPage: blok.linkToPage,
					image: blok.image?.filename,
				})
			}
		>
			<Link to={blok.linkToPage} className="z-10 overflow-hidden">
				<img
					alt="genderImage"
					className="w-full transform transition-all group-hover:scale-105"
					src={blok.image?.filename}
					fetchpriority="high"
				/>
				{!blok.removeOverlay && <BlackBackgroundColorForImages />}

				<div className="absolute bottom-2 left-2 flex flex-col md:bottom-4 md:left-4">
					{contentBlok.map(renderContentBlock)}
				</div>
			</Link>
		</div>
	)
}

export default MarketingImageCard
