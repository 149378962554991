import { Link } from '@remix-run/react'
import useAmplitudeContext from '#app/hooks/use-amplitude-context'
import { usePlentyMediaQuery } from '#app/hooks/use-plenty-media-query'
import { cn } from '#app/utils/misc'
import {
	type ImageLinkStoryblok,
	type SocialMediaLayoutStoryblok,
} from '#types/component-types-sb'
import PlentyBody from '../typography/plenty-body'
import PlentyHeading from '../typography/plenty-heading'

const SocialMediaLayout = ({ blok }: { blok: SocialMediaLayoutStoryblok }) => {
	return (
		<div className="space-y-4">
			<div className="m-auto flex w-full max-w-6xl flex-col">
				<PlentyHeading as="h7">{blok.title}</PlentyHeading>
				<PlentyBody size="md">{blok.subtitle}</PlentyBody>
			</div>
			{blok.gridMode ? <GridMode blok={blok} /> : <SimpleLayout blok={blok} />}
		</div>
	)
}

const SimpleLayout = ({ blok }: { blok: SocialMediaLayoutStoryblok }) => {
	const { trackAmplitudeEvent } = useAmplitudeContext()
	const images = blok.mediaContentBloks || []

	return (
		<div className="m-auto grid max-w-6xl grid-cols-3 gap-2 md:grid-cols-6 md:grid-rows-[repeat(1,180px)]  ">
			{images.map((socialMedia: ImageLinkStoryblok) => {
				return socialMedia.image.filename.endsWith('.mov') ||
					socialMedia.image.filename.endsWith('.mp4') ? (
					<Link
						to={socialMedia.link}
						key={socialMedia._uid}
						onClickCapture={() =>
							trackAmplitudeEvent('video social media clicked', {
								link: socialMedia.link,
							})
						}
					>
						<video
							src={socialMedia.image.filename}
							autoPlay
							muted
							loop
							playsInline
							fetchpriority={'low'}
							className="h-full max-h-[180px] w-full rounded-sm object-cover object-top md:w-full"
						/>
					</Link>
				) : (
					<Link
						to={socialMedia.link}
						key={socialMedia._uid}
						onClickCapture={() =>
							trackAmplitudeEvent('image social media clicked', {
								link: socialMedia.link,
							})
						}
					>
						<img
							src={socialMedia.image.filename}
							alt={socialMedia.image.alt}
							fetchpriority={'low'}
							loading="lazy"
							className="h-full max-h-[180px] w-full rounded-sm object-cover object-top md:w-full"
						/>
					</Link>
				)
			})}
		</div>
	)
}

const GridMode = ({ blok }: { blok: SocialMediaLayoutStoryblok }) => {
	const isDesktop = usePlentyMediaQuery('(min-width: 820px)')
	const { trackAmplitudeEvent } = useAmplitudeContext()

	const twoPerTwoIndexes = [4, 7]
	if (!blok.mediaContentBloks || blok.mediaContentBloks.length === 0) return
	const images = isDesktop
		? blok.mediaContentBloks
		: blok.mediaContentBloks.slice(0, 9)
	return (
		<div className="m-auto grid max-w-6xl grid-cols-3 grid-rows-[repeat(3,184px)] gap-2 md:grid-cols-6 md:gap-4 ">
			{images.map((socialMedia: ImageLinkStoryblok, index: number) => {
				return socialMedia.image.filename.endsWith('.mov') ||
					socialMedia.image.filename.endsWith('.mp4') ? (
					<Link
						to={socialMedia.link}
						key={socialMedia._uid}
						onClickCapture={() =>
							trackAmplitudeEvent('video social media clicked', {
								link: socialMedia.link,
							})
						}
					>
						<video
							src={socialMedia.image.filename}
							autoPlay
							muted
							loop
							playsInline
							fetchpriority={'low'}
							className={cn(
								'h-full w-full rounded-sm object-cover object-top md:w-full ',
								twoPerTwoIndexes.includes(index)
									? 'col-span-1 object-cover md:col-span-1 md:row-span-2 '
									: '',
							)}
						/>
					</Link>
				) : (
					<Link
						to={socialMedia.link}
						key={socialMedia._uid}
						onClickCapture={() =>
							trackAmplitudeEvent('image social media clicked', {
								link: socialMedia.link,
							})
						}
					>
						<img
							src={socialMedia.image.filename}
							key={socialMedia._uid}
							alt={socialMedia.image.alt}
							fetchpriority={'low'}
							loading="lazy"
							className={cn(
								'h-full w-full rounded-sm object-cover object-top md:w-full ',
								twoPerTwoIndexes.includes(index)
									? 'col-span-2 row-span-2 object-cover md:col-span-1 '
									: ' ',
							)}
						/>
					</Link>
				)
			})}
		</div>
	)
}
export default SocialMediaLayout
