import { Link } from '@remix-run/react'
import { storyblokEditable } from '@storyblok/react'
import useAmplitudeContext from '#app/hooks/use-amplitude-context'
import { type SearchMarketingCardStoryblok } from '#types/component-types-sb'
import BlackBackgroundColorForImages from '../black-background-color-for-images'
import PlentyBody from '../typography/plenty-body'
import PlentyHeading from '../typography/plenty-heading'

const SearchMarketingCard = ({ blok }: SearchMarketingCardStoryblok) => {
	const { trackAmplitudeEvent } = useAmplitudeContext()
	return (
		<div
			{...storyblokEditable(blok)}
			key={blok._uid}
			className="relative  overflow-hidden rounded-[8px] bg-cover bg-center bg-no-repeat md:max-h-full md:max-w-full"
			style={{ backgroundImage: `url(${blok.imageUrl})` }}
		>
			<Link
				to={blok.linkToPage}
				className="z-10"
				onClickCapture={() =>
					trackAmplitudeEvent('search marketing card clicked', {
						link: blok.linkToPage,
					})
				}
			>
				<div className="absolute inset-x-0 top-3 flex justify-center">
					{' '}
					<PlentyBody size="xs" className={`text-${blok.textColor}`}>
						{blok.topBody}
					</PlentyBody>
				</div>
				<img
					alt="genderImage"
					src={blok.image?.filename}
					fetchpriority="high"
				/>
				{!blok.removeOverlay && <BlackBackgroundColorForImages />}
				<div className="absolute bottom-2 left-2 flex flex-col">
					<PlentyBody size="sm" className={`text-${blok.textColor}`}>
						{blok.body}
					</PlentyBody>
					<PlentyHeading as="h7" className={`text-${blok.textColor}`}>
						{blok.heading}
					</PlentyHeading>
				</div>
			</Link>
		</div>
	)
}

export default SearchMarketingCard
