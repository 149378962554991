import { Link } from '@remix-run/react'
import { storyblokEditable } from '@storyblok/react'
import useAmplitudeContext from '#app/hooks/use-amplitude-context'
import { type MarketingImageBlokStoryblok } from '#types/component-types-sb'
import PlentyBody from '../typography/plenty-body'
import PlentyButtonsAndLinks from '../typography/plenty-buttons-and-links'
import PlentyHeading from '../typography/plenty-heading'
import { Icon } from '../ui/icon'

const MarketingImageBlok = ({ blok }: MarketingImageBlokStoryblok) => {
	const { trackAmplitudeEvent } = useAmplitudeContext()
	return (
		<div
			{...storyblokEditable(blok)}
			key={blok._uid}
			className="group flex h-full w-full flex-col overflow-hidden rounded-[8px] bg-mint-green-80p md:rounded-[16px]"
		>
			<Link
				className="flex h-full cursor-pointer flex-col justify-center space-y-4 bg-ampersandGreen bg-cover bg-no-repeat px-4 transition-transform group-hover:scale-105"
				to={blok.linkToPage}
				onClickCapture={() => {
					trackAmplitudeEvent('marketing image card clicked', {
						title: blok.title,
						linkToPage: blok.linkToPage,
						linkText: blok.linkText,
						image: blok.image?.filename,
					})
				}}
			>
				<PlentyHeading as="h1" className="text-4xl">
					{blok.title}
				</PlentyHeading>
				<PlentyBody>{blok.subTitle}</PlentyBody>
				<div className="group relative flex w-fit items-center">
					<PlentyButtonsAndLinks as="btn-sm" className="relative">
						{blok.linkText}
					</PlentyButtonsAndLinks>
					<Icon
						className="ml-2 h-4 w-4 rotate-180 transform transition-transform duration-300 group-hover:translate-x-1"
						name="arrow-left"
					/>
					<div className="absolute bottom-[1px] left-0 h-[4px] w-full bg-white opacity-50 transition-colors duration-300 group-hover:bg-beige-90" />
				</div>
			</Link>
		</div>
	)
}

export default MarketingImageBlok
