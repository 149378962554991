import { Link } from '@remix-run/react'
import { storyblokEditable } from '@storyblok/react'

import useAmplitudeContext from '#app/hooks/use-amplitude-context'
import { type SearchBrandHStackStoryblok } from '#types/component-types-sb'

import ButtonAndLinks from '../typography/button-and-links'
import PlentyBody from '../typography/plenty-body'
import { Icon } from '../ui/icon'

const SearchBrandHStack = ({ blok }: SearchBrandHStackStoryblok) => {
	const { trackAmplitudeEvent } = useAmplitudeContext()
	return (
		<div
			{...storyblokEditable(blok)}
			key={blok._uid}
			className="flex w-full flex-col items-start space-y-3"
		>
			<PlentyBody size="md" className="ml-2 text-beige-100">
				{blok.heading}
			</PlentyBody>
			<div className="grid grid-cols-2 space-x-4 overflow-x-auto scrollbar-hide md:grid-cols-8">
				{blok.brandLabels.map(
					(label: { filename: string; alt: string }, index: number) => (
						<Link
							key={index}
							to={`/${label.alt}`}
							className="flex-shrink-0"
							onClickCapture={() =>
								trackAmplitudeEvent('search brand label clicked', {
									label: label.alt,
								})
							}
						>
							<img
								src={label.filename}
								alt={label.alt}
								className="h-auto max-h-20 w-full object-contain"
							/>
						</Link>
					),
				)}
			</div>
			<Link
				to={'/brands'}
				className="flex flex-col "
				onClickCapture={() =>
					trackAmplitudeEvent('search see all brands clicked')
				}
			>
				<div className="z-10 flex items-center">
					<ButtonAndLinks className="ml-2" as="s" body={blok.navigationText} />
					<Icon className="text-body-md h-4 w-4" name="right-arrow" />
				</div>
				<div className="-mt-2 rounded-lg border-4 border-beige-50" />
			</Link>
		</div>
	)
}

export default SearchBrandHStack
