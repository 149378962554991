import { Link } from '@remix-run/react'
import { storyblokEditable } from '@storyblok/react'
import { useUtmParams } from '#app/hooks/useUtmParams'
import { trackBrandView } from '#app/utils/tracking/amplitude/brand-view'
import { type BrandCardStoryblok } from '#types/component-types-sb'
import BlackBackgroundColorForImages from '../black-background-color-for-images'

const BrandCard = ({ blok }: BrandCardStoryblok) => {
	const utmParams = useUtmParams()

	const amplitudeTracking = () => {
		trackBrandView({ redirectTo: blok.redirectTo }, utmParams)
	}

	return (
		<Link
			to={blok.redirectTo}
			className="group min-w-max"
			onClick={amplitudeTracking}
		>
			<div
				{...storyblokEditable(blok)}
				key={blok._uid}
				className="relative h-48 w-48 overflow-hidden rounded-lg"
			>
				<img
					src={blok.brandImage?.filename}
					alt="brand"
					className="absolute inset-0 h-full w-full rounded-lg object-cover transition-all group-hover:scale-110"
				/>
				<BlackBackgroundColorForImages className="rounded-lg" />

				<div className="rounded-xltransition absolute inset-0 flex items-center justify-center">
					<img src={blok.brandLabelImage?.filename} alt="brand" />
				</div>
			</div>
		</Link>
	)
}

export default BrandCard
